import datas from 'shared/Comments/Filter/lang.json'

export const filterWords = (messages: any) => {
  const regExpSource = datas.words.join('|')

  const regExpFilter = new RegExp(`\\b(${regExpSource})\\b`, 'gi')

  return messages.replace(regExpFilter, '***')
}

export const badWords = (messages: any) => {
  const regExpSource = datas.words.join('|')

  const regExpFilter = new RegExp(`\\b(${regExpSource})\\b`, 'gi')

  return messages.match(regExpFilter)
}
