import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { _Posts, _Recent } from 'types/types'

import { dateParse } from 'libs/dateParse'

const s = {
  container: `w-full h-fit `,
  title: `-mt-4 mb-10 text-4xl`,

  grid: `grid-1 gap-16 smx:grid-2`,

  card: `flex flex-col mdx:last:hidden`,
  link: `w-full h-full flexcenter flex-col underline underlinewhite transition-all delay-150 ease-out duration-300 
  hover:underlinered smx:flex-col`,

  image: `w-full h-[30rem] object-cover mdx:h-[23.5rem] smx:h-[38rem] xsx:h-[33rem] 2xsx:h-[27rem] 3xsx:h-[35rem] 4xsx:h-[32rem]`,

  body: `w-full h-fit flex flex-col pt-10 text-black`,
  subtitle: `-mt-4 mb-2 text-3xl leading-[3.25rem]`,
  date: `mb-0 text-sm`,
}

const Recent = ({ slugs }: _Recent) => {
  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        recent: getRecent {
          slug
          title
        }
        posts: getPostsList(size: 4, sort: { field: "date", order: "DESC" }) {
          items {
            _id
            slug
            title
            date
            image {
              title
              path
            }
          }
        }
      }
    }
  `)

  const { slug, title } = takeshape.recent
  const posts = takeshape.posts.items.filter(
    ({ slug }: _Posts) => slug !== slugs
  )

  return (
    <section className={s.container} id={slug}>
      <h2 className={s.title}>{title}</h2>

      <div className={s.grid}>
        {posts.map(({ _id, slug, title, date, image }: _Posts) => (
          <div key={_id} className={s.card}>
            <Link className={s.link} to={`/post/${slug}`}>
              <img
                className={s.image}
                src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
                alt={image.title}
              />
              <div className={s.body}>
                <h3 className={s.subtitle}>{title}</h3>
                <p className={s.date}>{dateParse(date)}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Recent
