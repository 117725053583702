import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { doc, updateDoc, setDoc, collection } from 'firebase/firestore'

import { _Content, _Form } from 'types/types'

import { useFirebaseCommentsContext } from 'hooks/comment-firebase-context'

import { db } from 'libs/firebase'

import { FormButton } from 'shared/Button'

const s = {
  form: `w-full h-fit mt-10`,
  maingroup: `w-full flex flex-col mb-8`,

  subgroup: `flex justify-between items-center`,
  label: `mb-2 text-2xl font-bold`,
  count: `text-xs text-darkgray`,

  input: `py-4 px-6 text-lg rounded border-[1px] border-solid border-gray bg-gray text-black focus:outline-none`,
  comment: `h-[12rem] py-4 px-6 text-lg rounded border-[1px] border-solid border-gray bg-gray text-darkgray focus:outline-none`,

  // CHECK BOX
  checkbox: `relative w-fit-message mb-20`,
  checklabel: `flex align-center mb-0 text-lg checklabel`,
  checkspan: `block checkspan cursor-pointer`,
  checkinput: `w-[2.5rem] h-[2.5rem] mr-4 appearance-none bg-gray border-[1px] border-solid border-gray rounded outline-none transition delay-300 ease-in curser-pointer checkinput checked:bg-gray checked:border-[1px] checked:border-solid checked:border-gray active:border-[1px] active:border-solid active:border-darkgray`,

  button: `block w-fit py-3 px-6 capitalize text-clamp-md text-white bg-black
  rounded border-[1px] border-solid border-black curser-pointer`,

  // ERROR MESSAGE
  error: `mt-2 text-red`,
}

const Form = ({
  parentID,
  slug,
  comments,
  edit,
  setEdit,
  show,
  setShow,
}: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    formState,
  } = useForm<_Form>({
    mode: 'onSubmit',
  })

  const { isLogin, user } = useFirebaseCommentsContext()

  const [value, setValue] = useState({
    name: '',
    message: '',
    checkbox: false,
  })

  const { name, message, checkbox } = value

  // FIND THE ID OF COMMENT
  const commentsID = comments?.findIndex((com: any) => com.id === parentID)

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    clearErrors(['name', 'message', 'checkbox'])
    setValue({ ...value, [e.target.name]: e.target.value, checkbox: true })
  }

  const onSubmit = handleSubmit(async (data, e: any) => {
    e.preventDefault()

    // CHECK FORM EDITS COMMENT
    if (edit) {
      // CHECK COMMENT ID TARGET
      if (commentsID !== -1) {
        // CHECK EDIT COMMENT MESSAGE VALUE IS EMPTY
        if (message === '') {
          setValue({
            ...value,
            message: comments[commentsID].message,
          })

          // CLOSE FORM AFTER EDIT COMMENT
          setEdit(false)
        } else {
          // UPDATE COMMENT AND SET TO FIREBASE
          const updateRef = doc(db, 'comments', `${comments[commentsID].id}`)

          // Set the "message" field of the updated message
          await updateDoc(updateRef, {
            message: message,
            edited: edit,
          })

          // CLOSE FORM AFTER EDIT COMMENT
          setEdit(false)
        }
      } else {
        // TOAST ERROR MESSAGE
        toast.error(`Something went wrong. Try again later.`)
      }
    } else {
      // CHECK IF USER IS LOGIN TO COMMENT
      if (isLogin) {
        // CREATE COMMENT AND SET COMMENT TO FIREBASE
        const docRef = doc(collection(db, `comments`))

        await setDoc(docRef, {
          id: docRef.id,
          slug: slug,
          name: name === '' ? 'Anonymous' : name,
          message: message,
          pId: parentID || null,
          userId: user[0]?.id || null,
          time: new Date(),
          checkbox: !checkbox,
          edited: false,
        })

        // CLOSE FORM AFTER SUBMIT COMMENT
        show && setShow(false)

        // RESET COMMENT VALUE
        setValue({
          ...value,
          name: '',
          message: '',
          checkbox: false,
        })

        // RESET CHECK BOX
        if (checkbox) e.target.reset()

        // TOAST SUCCES MESSAGE
        toast.success(`Thanks for the comment.`)
      } else {
        // TOAST ERROR MESSAGE LOGIN TO COMMENT
        toast.error(`Please, Log In to comment.`)
      }
    }
  })

  return (
    // COMMENT FORM
    <form className={s.form} onSubmit={(e) => onSubmit(e)}>
      <Toaster position="top-center" reverseOrder={false} />

      {!edit && (
        <div className={s.maingroup}>
          <div className={s.subgroup}>
            <label className={s.label} htmlFor="name">
              Name (Optional)
            </label>
            <p className={s.count}>{`${name.length}/20`}</p>
          </div>
          <input
            {...register('name', {
              required: false,
              minLength: 3,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i,
            })}
            className={s.input}
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            value={name}
            onChange={handleChange}
          />
          {errors.name && isLogin && (
            <p className={s.error}>
              Please fill in a name with between 3-20 characters long.
            </p>
          )}
        </div>
      )}

      <div className={s.maingroup}>
        <div className={s.subgroup}>
          <label className={s.label} htmlFor="message">
            Comment
          </label>
          <p className={s.count}>{`${message.length}/300`}</p>
        </div>
        <textarea
          {...register('message', {
            required: isLogin,
            maxLength: 300,
          })}
          className={s.comment}
          id="message"
          placeholder={edit ? comments[commentsID].message : `Your comment...`}
          name="message"
          value={message}
          onChange={handleChange}
        />
        {errors.message && isLogin && (
          <p className={s.error}>
            Please fill in a comment with max 300 characters long.
          </p>
        )}
      </div>

      {!edit && (
        <div className={s.checkbox}>
          <label className={s.checklabel} htmlFor="checkbox">
            <input
              {...register('checkbox', { required: isLogin })}
              className={s.checkinput}
              id="checkbox"
              type="checkbox"
              name="checkbox"
            />
            I'm a human.
            <span className={s.checkspan} onChange={handleChange}></span>
          </label>
          {errors.checkbox && isLogin && (
            <p className={s.error}>Please check the checkbox.</p>
          )}
        </div>
      )}

      <FormButton
        classname={s.button}
        disabled={isLogin && formState.isSubmitting}
      >
        {edit ? `Edit Comment` : `Post Comment`}
      </FormButton>
    </form>
  )
}

export default Form
