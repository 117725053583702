import React from 'react'

import { timeParse } from 'libs/dateParse'

import { filterWords, badWords } from 'shared/Comments/Filter/filterwords'

const s = {
  container: `w-full h-full p-8`,
  card: `flex flex-row items-start`,

  profile: `relative w-[5rem] h-[5rem] flex justify-center items-center`,
  background: `absolute z-[-1] w-full h-full`,
  text: `text-5xl`,

  content: `w-fit flex flex-col ml-4`,
  author: `-mt-1 mb-[0.65rem] flex flex-row justify-start items-center text-lg font-bold capitalize`,
  time: `ml-1 text-xs uppercase text-darkgray`,

  body: `mb-0 text-lg leading-[1.65]`,
}

const Comment = ({ comment }: any) => {
  return (
    // COMMENT BOX
    <div className={s.container}>
      <div className={s.card}>
        <div className={s.profile}>
          <div
            className={s.background}
            style={{ background: `hsl(${Math.random() * 360}, 100%, 75%)` }}
          />
          <h3>{badWords(comment.name) ? `A` : comment.name.charAt(0)}</h3>
        </div>

        <div className={s.content}>
          <p className={s.author}>
            {badWords(comment.name)
              ? `Anonymous -`
              : `${filterWords(comment.name)} -`}
            <span className={s.time}>
              {timeParse(Number(comment.time.seconds))}
            </span>
          </p>

          <p className={s.body}>{filterWords(comment.message)}</p>
        </div>
      </div>
    </div>
  )
}

export default Comment
