import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { doc, deleteDoc } from 'firebase/firestore'

import { db } from 'libs/firebase'

import { badWords } from 'shared/Comments/Filter/filterwords'
import Form from 'shared/Comments/Firebase/Form'

const s = {
  container: `w-full h-fit pt-8 px-8`,
  mainflex: `flex flex-row justify-between items-center`,

  head: `mr-auto text-darkgray`,
  span: `ml-1 text-xs`,
  group: `w-[3.25rem]`,
  button: `w-[1.25rem] h[1.25rem] cursor-pointer`,
  icon: `w-full h-full transiton-all delay-150 ease-out duration-300`,
  edit: `hover:filtericongreen`,
  delete: `hover:filtericonred`,

  confirm: `w-full h-full mt-8 flex justify-end items-center transition delay-150 ease-out 3xsx:flex-col`,
  message: `text-sm`,
  btns: `flex 3xsx:mt-4`,
  btn: `ml-4 py-2 px-5 bg-red text-white text-xs rounded-[0.25rem] last:bg-darkblack`,

  form: `mb-8`,
}

const Edit = ({ comments, comment, slug, commented }: any) => {
  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        comment: getComment {
          delete {
            title
            path
          }
          edit {
            title
            path
          }
        }
      }
    }
  `)

  const { delete: deletes, edit: edits } = takeshape.comment

  const [edit, setEdit] = useState(false)
  const [confirm, setConfirm] = useState(false)

  // CONFIRM CHECK SET TO TRUE FOR COMMENT
  const handleClick = () => (confirm ? setConfirm(false) : setConfirm(true))

  // CONFIRM EDIT SET TO TRUE FOR COMMENT
  const handleEdit = () => (edit ? setEdit(false) : setEdit(true))

  const handleConfirm = async () => {
    // FIND ID TO DELETE COMMENT && CONFIRM CHECK RESET
    const commentsID = comments?.findIndex((com: any) => com.id === comment.id)

    if (confirm) {
      // DELETE COMMENT FROM FIREBASE
      await deleteDoc(doc(db, 'comments', `${comments[commentsID].id}`))

      // SET CONFIRM DELETE TO FALSE
      setConfirm(!confirm)
    }
  }

  // CANCEL DELETE COMMENT
  const handleCancel = () => setConfirm(false)

  return (
    // COMMENT EDIT BOX
    <div className={s.container}>
      <div className={s.mainflex}>
        {/* HEAD COMMENT */}
        <p className={s.head}>
          {commented ? `Replied by ` : `Commented by `}
          {badWords(comment.name) ? `Anonymous` : comment.name}
          <span className={s.span}>{comment.edited && `(edit)`}</span>
        </p>

        {/* EDIT & DELETE BUTTONS COMMENT */}
        <div className={`${s.mainflex} ${s.group}`}>
          <div className={s.button} onClick={() => handleEdit()}>
            <img
              className={`${s.icon} ${s.edit}`}
              src={`https://images.takeshape.io/${edits.path}?q=70&fit=scale&h=120&w=120&fm=webp`}
              alt={edits.title}
            />
          </div>
          <div className={s.button} onClick={() => handleClick()}>
            <img
              className={`${s.icon} ${s.delete}`}
              src={`https://images.takeshape.io/${deletes.path}?q=70&fit=scale&h=120&w=120&fm=webp`}
              alt={deletes.title}
            />
          </div>
        </div>
      </div>

      {/* CONFIRM TO DELETE COMMENT */}
      {confirm && (
        <div className={s.confirm}>
          <p className={s.message}>Are you sure to delete this comment?</p>

          <div className={s.btns}>
            <button className={s.btn} onClick={() => handleConfirm()}>
              Yes!!!
            </button>
            <button className={s.btn} onClick={() => handleCancel()}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* EDIT COMMENT FORM */}
      {edit && (
        <div className={s.form}>
          <Form
            comments={comments}
            parentID={comment.id}
            slug={slug}
            edit={edit}
            setEdit={setEdit}
          />
        </div>
      )}
    </div>
  )
}

export default Edit
