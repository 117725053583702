import React from 'react'

const s = {
  container: `w-full h-fit mt-8 py-4 px-12 border-[1px] border-solid border-darkgray`,
  count: `text-lg uppercase`,
}

const Reply = ({ comments, slug }: any) => {
  const totalComments = comments.filter((comment: any) => comment.slug === slug)

  return (
    <div className={s.container}>
      <p className={s.count}>{`${totalComments.length} comments`}</p>
    </div>
  )
}

export default Reply
