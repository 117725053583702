import React from 'react'

import { LinkButton } from 'shared/Button'

const s = {
  container: `w-full h-fit`,
  list: `flex flexbetween flex-wrap list-none p-0`,
  items: `p-0`,
}

const Pagination = ({ page, prev, next }: any) => {
  const { prevPost, nextPost } = page

  return (
    <section className={s.container}>
      <ul className={s.list}>
        <li className={s.items}>
          {prevPost && <LinkButton to={`/post/${prev.slug}`}>Prev</LinkButton>}
        </li>
        <li className={s.items}>
          {nextPost && <LinkButton to={`/post/${next.slug}`}>Next</LinkButton>}
        </li>
      </ul>
    </section>
  )
}

export default Pagination
