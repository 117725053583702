import { useState } from 'react'
import {
  animated,
  useChain,
  useSpring,
  useSpringRef,
  easings,
} from 'react-spring'

export const useAnimation = () => {
  const [animate, setAnimate] = useState(false)

  const contentApi = useSpringRef()

  const onAnimate = (inView: boolean) => {
    if (!animate && inView) setAnimate(true)
  }

  const fadeInContent = useSpring({
    to: { opacity: animate ? 1 : 0 },
    delay: 500, //500
    config: {
      easing: easings.easeInOutQuint,
      duration: 1000, //800
    },
    ref: contentApi,
  })

  useChain(animate ? [contentApi] : [], [0.3])

  return {
    onAnimate,
    animated,
    fadeInContent,
  }
}
