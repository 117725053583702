import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'
import Markdown from 'markdown-to-jsx'
import { collection, query, where, onSnapshot } from 'firebase/firestore'

import { db } from 'libs/firebase'
import { dateParse } from 'libs/dateParse'

import { useFirebaseCommentsContext } from 'hooks/comment-firebase-context'

import { useAnimation } from 'template/Animation'
import Recent from 'main/Post/Recent'
import Related from 'main/Post/Related'
import Seo from 'shared/Seo'
import Layout from 'shared/Layout'
import Pagination from 'shared/Pagination/Pagination'
import Comments from 'shared/Comments/Firebase'
import { LinkButton } from 'shared/Button'

interface Datas {
  pageContext: any
  data: {
    takeshape: {
      post: {
        slug: string
        title: string
        date: string
        description: string
        image: {
          title: string
          path: string
        }
      }
    }
  }
}

const s = {
  container: `w-full h-fit mt-60 mx-0 mb-40`,
  wrapper: `wrapper`,
  mainflex: `w-full h-fit flex justify-between flex-row smx:flex-col`,

  card: `w-fit h-fit `,
  image: `w-full h-[60rem] mb-12 object-cover xlx:h-[55rem] lgx:h-[50rem] mdx:h-[40rem] smx:h-[50rem]
  xsx:h-[40rem] 2xsx:h-[35rem] 3xsx:h-[35rem] 4xsx:h-[32rem]`,

  body: `w-full h-fit flex flex-col mb-12`,
  title: `w-4/6 mb-2 leading-[3.25rem] 3xsx:w-full`,
  date: `mb-1 text-sm`,
  description: `text-justify post`,

  subflex: `w-[59rem] h-fit ml-40 flex flex-col mdx:ml-28 smx:w-full smx:mt-40 smx:ml-0`,
  button: `block ml-auto`,
}

const Post = ({ data, pageContext }: Datas) => {
  const { animated, fadeInContent, onAnimate } = useAnimation()
  const { comments, setComments } = useFirebaseCommentsContext()
  const { prevPost, nextPost } = pageContext
  const { slug, title, date, description, image } = data.takeshape.post

  // GET FIREBASE COMMENTS
  useEffect(() => {
    const docRef = query(
      collection(db, `comments`),
      where(`slug`, `==`, `${slug}`)
    )

    const unsubscribe = onSnapshot(docRef, (querySnapshot) => {
      let coms: any[] = []

      querySnapshot.forEach((doc: any) => {
        coms.push(doc.data())
      })

      setComments(coms)
    })

    return () => unsubscribe()
  }, [])

  // NAVIGATION HIGHLIGHT LINKS
  useEffect(() => {
    linkHighlight()
  })

  const linkHighlight = () => {
    const links = document.querySelectorAll('.link')

    links.forEach((link: any) => {
      link.classList.contains('blog') && link.classList.add('highlight')
    })
  }

  return (
    <Layout backgrounds="bg-none">
      <Seo title={title} description={description.slice(0, 100)} />
      <section className={s.container}>
        <div className={s.wrapper}>
          <InView as="div" rootMargin="0px" onChange={onAnimate}>
            <animated.div className={s.mainflex} style={fadeInContent}>
              <div className={s.card}>
                <img
                  className={s.image}
                  src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
                  alt={image.title}
                />
                <div className={s.body}>
                  <h3 className={s.title}>{title}</h3>
                  <p className={s.date}>{dateParse(date)}</p>

                  <Markdown className={s.description}>{description}</Markdown>
                </div>
                <Pagination
                  page={pageContext}
                  prev={prevPost}
                  next={nextPost}
                />
                {/* POSTS COMMENTS */}
                <Comments comments={comments} slug={slug} />
              </div>

              <div className={s.subflex}>
                <Recent slugs={slug} />
                <Related show={true} />
                <LinkButton classname={s.button} to={`/posts`}>
                  All News
                </LinkButton>
              </div>
            </animated.div>
          </InView>
        </div>
      </section>
    </Layout>
  )
}

export default Post

export const queries = graphql`
  query ($projectId: ID!) {
    takeshape {
      post: getPosts(_id: $projectId) {
        slug
        title
        date
        description
        image {
          title
          path
        }
      }
    }
  }
`
