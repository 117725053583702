import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import toast, { Toaster } from 'react-hot-toast'

import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'

import { useFirebaseCommentsContext } from 'hooks/comment-firebase-context'

import { auth } from 'libs/firebase'

import Total from 'shared/Comments/Firebase/Total'
import Reply from 'shared/Comments/Firebase/Reply'
import Form from 'shared/Comments/Firebase/Form'

const s = {
  container: `w-full h-fit mt-40`,
  maingroup: `flex flex-col mb-28`,
  title: `mb-0 border-b-[1px] border-solid border-black`,

  subgroup: `flex justify-end items-center`,
  head: `w-full flex justify-between items-center`,
  subtitle: `text.sm`,
  button: `mt-8 px-6 py-2 text-sm border-[1px] border-solid border-black 
  transition delay-150 ease-out duration-300 hover:bg-black hover:text-white`,
}

const Comments = ({ comments, slug }: any) => {
  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        comment: getComment {
          title
        }
      }
    }
  `)

  // SET STATE LOGIN USER TO COMMENT
  const { isLogin, setLogin, user, setUser } = useFirebaseCommentsContext()

  // CHECK POST COMMENT BY FILTER SLUG
  const postcomments = comments.filter((comment: any) => comment.slug === slug)

  // GET USER LOG IN WITH FIREBASE GOOGLE
  const signInGoogle = async () => {
    const provider = new GoogleAuthProvider()

    //SIGN IN WITH GOOGLE USER ACCOUNT
    await signInWithPopup(auth, provider)
      .then((res) => {
        const { uid, displayName, email } = res.user

        const isUser = {
          id: uid,
          name: displayName,
          email: email,
        }

        setLogin(true)
        setUser((prev: any) => [...prev, isUser])

        // TOAST SUCCES MESSAGE
        toast.success(`Logged in as ${user[0].name.charAt(0)}.`)
      })
      .catch((error: any) => {
        if (error.code === 'auth/invalid-email') {
          toast.error('Email is invalid')
        }

        if (error.code === 'auth/user-not-found') {
          toast.error('Please check email')
        }

        if (error.code === 'auth/wrong-password') {
          toast.error('Please check password')
        }
      })
  }

  // SIGN OUT WITH FIREBASE GOOGLE
  const signOutGoogle = async () => {
    //SIGN OUT USER ACCOUNT
    await signOut(auth)
      .then(() => {
        // CLEAR LOGIN AND USER
        setLogin(false)
        setUser([])
      })
      .catch((error: any) => {
        const message = error.message
        toast.error(message)
      })
  }

  const { title } = takeshape.comment

  return (
    <section className={s.container}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 8000 }}
      />
      <div className={s.maingroup}>
        <h2 className={s.title}>{title}</h2>

        {/* FIREBASE LOGIN AND LOGOUT */}
        <div className={s.subgroup}>
          {isLogin ? (
            <div className={s.head}>
              <p className={s.subtitle}>{`Welcome ${user[0]?.email}`}</p>
              <button className={s.button} onClick={() => signOutGoogle()}>
                Log Out
              </button>
            </div>
          ) : (
            <button className={s.button} onClick={() => signInGoogle()}>
              Log In with Google
            </button>
          )}
        </div>

        {/* MAIN COMMENT FORM */}
        <Form slug={slug} />
      </div>

      {/* TOTAL COUNT COMMENTS */}
      <Total comments={comments} slug={slug} />

      {/* COMMENT BOX */}
      {postcomments.length > 0 &&
        comments
          .filter((comment: any) => !comment.pId)
          .map((comment: any, id: number) => {
            // FIND ALL THE REPLYS
            let replys

            if (comment.id) {
              replys = comments.filter((c: any) => c.pId === comment.id)
            }

            return (
              <Reply
                key={id}
                replys={replys}
                comments={comments}
                comment={comment}
                slug={slug}
              />
            )
          })}
    </section>
  )
}

export default Comments
