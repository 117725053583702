import React, { useState } from 'react'

import { useFirebaseCommentsContext } from 'hooks/comment-firebase-context'

import Comment from 'shared/Comments/Firebase/Comment'
import Edit from 'shared/Comments/Firebase/Edit'
import Form from 'shared/Comments/Firebase/Form'
import { ClickButton } from 'shared/Button'

const s = {
  container: `w-full h-fit mt-8 p-12 border-[1px] border-solid border-darkgray`,
  mainflex: `flex flex-col items-start bg-gray`,
  subflex: `mb-8 last:mb-0`,
  group: `flex flex-col`,

  subcontainer: (show: boolean) => `${show ? `pt-16 pl-12` : `p-0`} `,

  button: `block w-fit mt-12 px-4 py-3 capitalize text-clamp-sm text-white bg-black
  rounded border-[1px] border-solid border-black curser-pointer lgx:px-6`,
}

const Reply = ({ slug, comments, comment, replys }: any) => {
  const [show, setShow] = useState(false)
  const { user } = useFirebaseCommentsContext()

  return (
    <div className={s.container}>
      {/* MAIN REPLY COMMENT BOX */}
      <div className={s.mainflex}>
        {/*  CHECK COMMENTS ID WITH USER ID FOR EDIT & DELETE COMMENT */}
        {user[0]?.id === comment.userId && (
          <Edit comments={comments} comment={comment} slug={slug} />
        )}
        <Comment comment={comment} />
      </div>

      {/* REPLY MAIN COMMENT BUTTON */}
      {show ? (
        <div className={s.group}>
          <ClickButton classname={s.button} handleClick={() => setShow(false)}>
            Cancel Reply
          </ClickButton>
          <Form
            comments={comments}
            parentID={comment.id}
            slug={slug}
            show={show}
            setShow={setShow}
          />
        </div>
      ) : (
        <ClickButton classname={s.button} handleClick={() => setShow(true)}>
          Reply
        </ClickButton>
      )}

      {/* SUB REPLY COMMENT BOX*/}
      {replys && (
        <div className={s.subcontainer(replys.length > 0)}>
          {replys.map((reply: any, id: number) => (
            <div className={`${s.mainflex} ${s.subflex}`} key={id}>
              {/*  CHECK COMMENTS ID WITH USER ID FOR EDIT & DELETE COMMENT */}
              {user[0]?.id === reply.userId && (
                <Edit
                  comments={comments}
                  comment={reply}
                  slug={slug}
                  commented={replys.length > 0}
                />
              )}
              <Comment comment={reply} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Reply
